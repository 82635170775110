import React from 'react';
import { graphql } from 'gatsby';
import Component from '@reach/component-component';
import { FiX, FiPlay } from 'react-icons/fi';
import { get } from 'lodash';
import styled from 'styled-components';
import App from '../components/App';
import SEO from '../components/SEO';
import Header, {
  ViewportOverlay,
  ModalWrapper,
  ModalContainer,
  ModalCloseButton,
  SVGSpan,
} from '../components/HeroInterior';
import Grid from '../components/Grid';
import Container from '../components/Container';
import Wrapper from '../components/Wrapper';
import { CardLinkWrapper } from '../components/CardResource';
import CallToAction from '../components/CallToAction';
import theme from '../theme';

const VideoTile = styled.div`
  ${CardLinkWrapper}
  cursor: pointer;
  height: auto;
  position: relative;
  width: 49%;

  @media (max-width: 768px){
    width: 100%;
  }

  h4 {
    margin: 0;
  }
`;

const IconButton = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  color: ${theme.secondary};
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;

  svg {
    background-color: ${theme.primary};
    box-shadow: 3px 12px 22px rgba(2,2,2,0.1);
    border-radius: 50%;
    fill: #FFF;
    stroke: #FFF;
    margin-right: .75rem;
    padding: .5rem;
  }
`;

export default function VideoPage({ data }) {
  const { contentfulTrainingVideoPage } = data;
  const { hero, videoGalleries, callToAction } = contentfulTrainingVideoPage;
  return (
    <App>
      <SEO {...contentfulTrainingVideoPage} />
      <Header
        slug={contentfulTrainingVideoPage.slug}
        version={hero.version.toLowerCase()}
        title={hero.title}
        subtitle={hero.subtitle.subtitle}
      />
      <main >
        <Wrapper variant="muted">
          <Container>
            {videoGalleries.map((gallery, i) => (
              <div key={gallery.contentful_id} style={{ marginBottom: '3rem' }}>
                <h2 id={gallery.contentful_id} style={{ textAlign: 'center' }}>
                  {gallery.title}
                </h2>
                <Grid>
                  {gallery.videos.map(video => (
                    <Component initialState={{ showDialog: false }}>
                      {({ state, setState }) => (
                        <>
                          <VideoTile onClick={() => setState({ showDialog: true })}>
                            <h4>{video.title}</h4>
                            <p>
                              <small>{get(video, 'description.description')}</small>
                            </p>

                            <IconButton>
                              <FiPlay size="22" />
                              {'Watch Now'}
                            </IconButton>

                          </VideoTile>
                          <ViewportOverlay isOpen={state.showDialog}>
                            <ModalWrapper>
                              <ModalContainer>
                                <ModalCloseButton
                                  aria-label="Close Modal"
                                  onClick={() => setState({ showDialog: false })}
                                >
                                  <SVGSpan>
                                    <FiX size="32" />
                                  </SVGSpan>
                                </ModalCloseButton>
                                <iframe
                                  title="WordLX video"
                                  src={video.url}
                                  style={{
                                    height: '450px',
                                    width: '100%',
                                  }}
                                  frameBorder="0"
                                  allowFullscreen
                                />
                              </ModalContainer>
                            </ModalWrapper>
                          </ViewportOverlay>
                        </>
                      )}
                    </Component>
                  ))}
                </Grid>
              </div>
            ))}
          </Container>
        </Wrapper>
        <CallToAction {...callToAction} />
      </main>
    </App>
  );
}

export const query = graphql`
  query getById($contentful_id: String){  
    contentfulTrainingVideoPage(contentful_id: { eq: $contentful_id }) {
      title 
      slug
      description {
        description
      }
      hero {
        ...HeroDetail
      }
      videoGalleries {
        contentful_id
        title
        videos {
          contentful_id
          title
          description {
            description
          }
          url
        }
      }
      callToAction {
        ...CallToActionDetail
      }
    }
  }
`;
